@import "../styles/Variables.scss";
@import "../styles/Mixins.scss";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
.home {
  margin: 0 auto;
  width: 95vw;
  user-select: none;
  text-align: left;
  border: 0;
  font: inherit;
  padding: 0;
  vertical-align: baseline;
  color: #707070;
  line-height: 1.4;

  @include from($medium) {
    width: 80%;
  }

  .container-text {
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 3px 3px 3px 3px #0000000c;
    margin-top: 30px;
    margin-bottom: 20px;

    .text {
      height: 400px;
      overflow: auto;
      overflow-x: hidden;
      font-family: 'Montserrat-regular', sans-serif;
      padding: 15px;

      h2 {
        text-align: center;
        letter-spacing: 0px;
        font-size: 1.563rem;
        font-weight: 900;
        color: #251a4c;

        @media screen and (max-width: 768px) {
          font-size: 3.5vw;
        }
      }
    }
  }

  .form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .content-form {
    margin: 20px;
    background: #251a4c;
    height: fit-content;
    border-radius: 10px;
    padding: 10px;
  }

  .container-order-last-mobile {
    margin: 20px;
    display: flex;
    height: fit-content;
    border-radius: 10px;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .container-label {
    height: fit-content;
    padding: 10px;
    display: flex;
    justify-content: center;
  }

  .container-btn-enviar{
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
  }

  .label {
    font-size: 15px;
    align-content: center;
  }

  .sign-controls {
    margin-top: 20px;
    margin-left: 0;
    display: flex;
    justify-content: center;
  }
  .content-components {
    width: 50%;
    box-sizing: border-box;
  }
  @media (max-width: 768px) {
    .content-components {
      width: 100%;
    }
  }

  .content-input {
    margin: 10px;
  }

  .mssg {
    font-size: xx-large;
    color: green;
    font-weight: bold;

    &-error {
      font-size: xx-large;
      color: red;
      font-weight: bold;
    }
  }
  .header1 {
    border: 0;
    padding: 10px;
    color: white;
    text-align: center;
    font-size: 1.438rem;
    font-family: 'Montserrat-Bold', sans-serif;
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
      font-size: 3.5vw;
    }

  }

  .btn-enviar {
    width: 150px;
    cursor: pointer;
    background-color: #40b6d2;
    border: 1px solid white;
    padding: 10px;
    border-radius: 10px;
    color: white;
    font-size: 15px;
    font-family: 'Montserrat-Bold', sans-serif;
    text-transform: uppercase;
    margin-left: 30px;
    transition: background-color 0.3s, color 0.3s;
    @media screen and (max-width: 768px) {
      width: fit-content;
      height: fit-content;
    }
  }
  .btn-enviar:hover {
    background-color: #1A1333;
  }

  .forms {
    display: flex;
    justify-content: center;

    .container-form {
      margin: 0 10px;
    }

    .submit-container {
      margin: 50px 0;
      width: 100%;
    }
  }
}
