@import "../../../styles//Variables.scss";
@import "../../../styles/Mixins.scss";

.input {
  display: flex;
  align-items: center;
  justify-content: space-between;  
  justify-content: space-around;

  .container-label,
  .container-input {
    margin: 1px;
    margin-bottom: 10px;
    border: 1px solid rgb(196, 191, 191);
    border-radius: 5px;
    background: white;
  }

  .container-label {
    padding: 1%;

    label {
      width: 100%;
      height: 100%;
    }
  }

  .container-input {
    width: 350px;
    max-width: 350px;

    input,
    select,
    textarea {
      width: 90%;
      height: 100%;      
      margin: 5px;
      border: none;     
    }
  }
}

.label {
  &-small {
    width: 200px;
  }

  &-medium {
    width: 300px;
  }

  &-long {
    width: 500px;
  }
}