@import "../../../styles/Variables.scss";
@import "../../../styles/Mixins.scss";

.body {
  display: flex;
  flex-flow: column;

  @include from($medium) {
    padding: 10% 10%;
  }
}