@import "../../../styles/Variables.scss";
@import "../../../styles/Mixins.scss";

.banner {
  width: 100%;
  overflow: hidden;

  @include from($medium) {
    width: 100%;
  }
}