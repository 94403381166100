/* Fonts */
$ruta: "/fonts/";

@mixin newFont($path, $fontFamily, $fileName) {
    $path: $path +'/'+ $fileName;

    @font-face {
        font-family: $fontFamily;
        src: url($path + '.eot');
        src: url($path +'.eot?#iefix') format('embedded-opentype'),
            url($path +'.woff') format('woff'),
            url($path +'.ttf') format('truetype'),
            url($path +'.svg#'+$fontFamily) format('svg');
        font-weight: 500;
        font-style: normal;
    }
}

@include newFont($ruta+'fsjoey', 'joeyBold', 'original_FSJoey-Bold');
@include newFont($ruta+'fsjoey', 'joeyHeavy', 'original_FSJoey-Heavy');
@include newFont($ruta+'fsjoey', 'joeyLight', 'original_FSJoey-Light');
@include newFont($ruta+'fsjoey', 'joeyMedium', 'original_FSJoey-Medium');
@include newFont($ruta+'fsjoey', 'joeyRegular', 'original_FSJoey-Regular');
@include newFont($ruta+'revicons', 'revicons', 'revicons');