@import "../styles/Variables.scss";
@import "../styles/Mixins.scss";

.btn {
  width: max-content;
  background-color: #251A4C;
  border: 1px solid white;
  padding: 10px;  
  border-radius: 10px;
  color: white;
  font-size: 13px;
  cursor: pointer;
  text-transform: uppercase;
  margin-top: 5px;  
}
.btn:hover {
  background-color: #40b6d2;
}

.table {
  display: flex;
  flex-flow: column;
  border: 1px solid black;
  padding: 2px;
  width: max-content;

  @include from($medium) {
    width: 80vw;
  }
}

.hidden {
  display: none;
}